<script>
import CartSummary from '../components/CartSummary.vue';

export default {
  components: {
    CartSummary,
  },
  mounted() {
    document.title = this.$t("checkout.summary"); 
  },
};
</script>

<template>
  <div class="my-6 mx-auto max-w-xl">
    <div class="text-left">
      <p class="text-4xl">{{ $t("checkout.summary") }}</p>
    </div>

    <CartSummary class="top-0 sticky" />

    <div class="flex justify-end">
      <localized-link :to="{ name: 'checkoutCustomerType' }"
        class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
        {{ $t("checkout.button.next") }}
      </localized-link>
    </div>
  </div>
</template>
